.card-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.card {
  display: flex;
  flex-direction: row;
  background-color: #f8f8f8;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  width: 100%;
  max-width: 600px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s;
}

.card:hover {
  transform: scale(1.05);
}

.card-thumbnail {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.card-body {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-title {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
}

.card-status, .card-author, .card-price, .card-views, .card-likes {
  margin-top: 5px;
}

@media only screen and (max-width: 768px) {
  .card-list {
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }

  .card {
      flex-direction: column;
      width: 100%;
      max-width: 100%;
  }

  .card-thumbnail {
      width: 100%;
      height: auto;
  }

  .card-body {
      padding: 15px;
  }

  .card-title {
      font-size: 1em;
  }

  .card-status, .card-author, .card-price, .card-views, .card-likes {
      font-size: 0.8em;
  }
}

@media only screen and (min-width: 769px) {
  .card-list {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 20px;
  }

  .card {
      flex-direction: row;
      max-width: 100%;
  }

  .card-thumbnail {
      width: 150px;
      height: 150px;
  }

  .card-body {
      padding: 20px;
  }

  .card-title {
      font-size: 1.2em;
  }

  .card-status, .card-author, .card-price, .card-views, .card-likes {
      font-size: 1em;
  }
}
